h1, .h1 {
    font-size: $h1;
    line-height: 56px;
    @include breakAt(md-device) {
        font-size: $h1-md;
    }
}
h2, .h2 {
    font-size: $h2;
    @include breakAt(md-device) {
        font-size: $h2-md;
    }
}
h3, .h3 {
    font-size: $h3;
    @include breakAt(md-device) {
        font-size: $h3-md;
    }
}
h4, .h4 {
    font-size: $h4;
}
h5, .h5 {
    font-size: $h5;
}
h6, .h6 {
    font-size: $h6;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    color: $black-200;
    line-height: 1.3;
}
h1 a:hover, a.h1:hover, h2 a:hover, a.h2:hover, h3 a:hover, a.h3:hover, h4 a:hover, a.h4:hover, h5 a:hover, a.h5:hover, h6 a:hover, a.h6:hover {
    color: $color-primary;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
    margin: 0;
}
p {
    line-height: 1.75;
}
a:focus,
button:focus {
	outline: 0;
}
a {
    text-decoration: none;
    transition: all .3s;
}
a:hover {
    text-decoration: underline;
	color: initial;
}
ol, ul {
    list-style-position: inside;
}
ul, li {
	padding: 0;
	margin: 0;
}