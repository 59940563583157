/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Copper
VERSION:	v1.0
-------------------------------------------------------------------*/
/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500;600;700&display=swap");
h1, .h1 {
  font-size: 46px;
  line-height: 56px;
}

@media (max-width: 767px) {
  h1, .h1 {
    font-size: 38px;
  }
}

h2, .h2 {
  font-size: 36px;
}

@media (max-width: 767px) {
  h2, .h2 {
    font-size: 28px;
  }
}

h3, .h3 {
  font-size: 24px;
}

@media (max-width: 767px) {
  h3, .h3 {
    font-size: 22px;
  }
}

h4, .h4 {
  font-size: 20px;
}

h5, .h5 {
  font-size: 18px;
}

h6, .h6 {
  font-size: 15px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  color: #222222;
  line-height: 1.3;
}

h1 a:hover, a.h1:hover, h2 a:hover, a.h2:hover, h3 a:hover, a.h3:hover, h4 a:hover, a.h4:hover, h5 a:hover, a.h5:hover, h6 a:hover, a.h6:hover {
  color: #FF3158;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p {
  margin: 0;
}

p {
  line-height: 1.75;
}

a:focus,
button:focus {
  outline: 0;
}

a {
  text-decoration: none;
  transition: all .3s;
}

a:hover {
  text-decoration: underline;
  color: initial;
}

ol, ul {
  list-style-position: inside;
}

ul, li {
  padding: 0;
  margin: 0;
}

body {
  color: #333333;
  background-color: #ffffff;
  font-family: "Quicksand", sans-serif;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.65;
}

::selection {
  color: #ffffff;
  background-color: #ff1843;
  text-shadow: none;
}

:focus {
  outline: 0;
}

.preloader {
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999999;
  text-align: center;
  overflow: hidden;
  transition: 0.4s ease-out;
  background-color: #f4f4f4;
}

.preloader .block .loader-image {
  transition: 0.3s ease-out;
  height: 90px;
  width: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  position: relative;
}

.preloader .block .loader-image::after {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 44%;
  border: 1px solid;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    border-color: rgba(255, 49, 88, 0.4);
    transform: rotateZ(0);
  }
  25% {
    border-color: rgba(255, 187, 51, 0.4);
    transform: rotateZ(90deg);
  }
  50% {
    border-color: rgba(255, 49, 88, 0.4);
    transform: rotateZ(180deg);
  }
  75% {
    border-color: rgba(30, 133, 139, 0.4);
    transform: rotateZ(270deg);
  }
  100% {
    border-color: rgba(255, 49, 88, 0.4);
    transform: rotateZ(360deg);
  }
}

.preloader .block .loader-image img {
  height: 30px;
}

.preloader .block .loader-text span {
  display: block;
  transition: 0.3s ease-out;
}

.preloader .block .loader-text span:nth-child(1) {
  font-size: 24px;
  font-weight: 300;
}

.preloader .block .loader-text span:nth-child(2) {
  font-size: 36px;
  font-weight: 700;
}

.preloader.loaded {
  height: 0;
  transition-delay: .8s;
}

.preloader.loaded .block .loader-image {
  opacity: 0;
  transform: translateY(-40px);
}

.preloader.loaded .block .loader-text span:nth-child(1) {
  opacity: 0;
  transform: translateY(-35px) rotateZ(-5deg);
  transition-delay: 0.2s;
}

.preloader.loaded .block .loader-text span:nth-child(2) {
  opacity: 0;
  transform: translateY(-30px) rotateZ(-5deg);
  transition-delay: 0.4s;
}

/*------------------------------------------------------------------
 # default styles
-------------------------------------------------------------------*/
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1170px;
  }
}

.header-nav,
.banner,
.page-header,
.section-padding {
  opacity: 0;
}

body.loaded .header-nav,
body.loaded .banner,
body.loaded .page-header,
body.loaded .section-padding {
  opacity: 1;
}

.section-padding {
  padding: 80px 0;
}

.section-title {
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.badge {
  color: #ffffff !important;
  font-size: 12px;
  font-weight: 600;
  padding: .45em .8em;
  border-radius: 4px;
}

.badge.badge-changed {
  background-color: #3A9CFF;
}

.badge.badge-added {
  background-color: #00CE92;
}

.badge.badge-removed {
  background-color: #973CFF;
}

.badge.badge-security {
  background-color: #9C275F;
}

.badge.badge-depreciate {
  background-color: #FF3158;
}

.divider-text {
  position: relative;
  text-align: center;
  z-index: 1;
}

.divider-text span {
  padding: 0 15px;
}

.divider-text::after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background-color: #CECECE;
  left: 0;
  top: calc(50% - 0.03125rem);
  z-index: -1;
}

.has-shapes {
  position: relative;
  z-index: 1;
}

.has-shapes .shape-xl,
.has-shapes .shape-lg,
.has-shapes .shape-md,
.has-shapes .shape-sm,
.has-shapes .shape-sm-2,
.has-shapes .shape-xs,
.has-shapes .shape-xs-2 {
  position: absolute;
  pointer-events: none;
  z-index: -1;
}

.has-shapes .shape-xl {
  height: 105px;
  width: 105px;
}

.has-shapes .shape-lg {
  height: 93px;
  width: 93px;
}

.has-shapes .shape-md {
  height: 76px;
  width: 76px;
}

.has-shapes .shape-sm {
  height: 55px;
  width: 55px;
}

.has-shapes .shape-sm-2 {
  height: 35px;
  width: 35px;
}

.has-shapes .shape-xs {
  height: 26px;
  width: 26px;
}

.has-shapes .shape-xs-2 {
  height: 15px;
  width: 15px;
}

.has-shadow {
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
}

.has-shadow::after {
  position: absolute;
  content: "";
  height: calc(100% - 40px);
  width: calc(100% - 40px);
  bottom: 0;
  background-color: #E0E0E0;
  z-index: -1;
  border-radius: 5px;
}

.has-shadow.shadow-light::after {
  background-color: #f6f6f6;
}

@media (max-width: 767px) {
  .has-shadow {
    padding-bottom: 25px;
  }
  .has-shadow::after {
    height: calc(100% - 25px);
    width: calc(100% - 25px);
  }
}

.has-shadow.has-shapes .shape-1 {
  top: -30px;
}

.has-shadow.has-shapes .shape-2 {
  bottom: 0;
  z-index: 2;
}

@media (max-width: 767px) {
  .has-shadow.has-shapes .shape-2 {
    height: 80px;
    width: 80px;
  }
}

.has-shadow.has-shapes .shape-3 {
  bottom: calc(50% - 40px);
  z-index: 2;
  height: 81px;
  width: 81px;
}

@media (max-width: 767px) {
  .has-shadow.has-shapes .shape-3 {
    height: 50px;
    width: 50px;
  }
}

.has-shadow.shadow-right {
  padding-right: 40px;
}

.has-shadow.shadow-right::after {
  right: 0;
}

@media (max-width: 767px) {
  .has-shadow.shadow-right {
    padding-right: 25px;
    padding-bottom: 25px;
  }
  .has-shadow.shadow-right::after {
    height: calc(100% - 25px);
    width: calc(100% - 25px);
  }
}

.has-shadow.shadow-right.has-shapes .shape-1 {
  left: -30px;
}

.has-shadow.shadow-right.has-shapes .shape-2 {
  left: -40px;
}

.has-shadow.shadow-right.has-shapes .shape-3 {
  right: 0;
}

.has-shadow.shadow-left {
  padding-left: 40px;
}

.has-shadow.shadow-left::after {
  left: 0;
}

@media (max-width: 767px) {
  .has-shadow.shadow-left {
    padding-left: 25px;
    padding-bottom: 25px;
  }
  .has-shadow.shadow-left::after {
    height: calc(100% - 25px);
    width: calc(100% - 25px);
  }
}

.has-shadow.shadow-left.has-shapes .shape-1 {
  right: -30px;
  left: auto;
}

.has-shadow.shadow-left.has-shapes .shape-2 {
  right: -40px;
  top: auto;
}

.has-shadow.shadow-left.has-shapes .shape-3 {
  left: 0;
}

.check-list li {
  position: relative;
  padding-left: 25px;
}

.check-list li svg {
  position: absolute;
  left: 0;
  top: 6px;
}

.check-list-2 li, .check-list-primary li {
  position: relative;
  padding-left: 35px;
  font-size: 16px;
  font-weight: 600;
}

.check-list-2 li span, .check-list-primary li span {
  position: absolute;
  left: 0;
  top: 6px;
  z-index: 1;
}

.check-list-2 li span svg, .check-list-primary li span svg {
  height: 8px;
  width: 12px;
  color: #33B27C;
  position: absolute;
  top: 3px;
  left: 8px;
}

.check-list-2 li span::after, .check-list-primary li span::after {
  position: absolute;
  content: "";
  height: 27px;
  width: 27px;
  left: 0;
  top: -7px;
  background-color: #E1F4EC;
  border-radius: 50px;
  z-index: -1;
}

.check-list-primary li {
  padding-left: 30px;
  font-size: 14px;
  font-weight: 500;
}

.check-list-primary li span {
  top: 6px;
}

.check-list-primary li span svg {
  height: 7px;
  width: 11px;
  color: #FF3158;
  top: 3px;
  left: 5px;
}

.check-list-primary li span::after {
  height: 20px;
  width: 20px;
  top: -4px;
  background-color: rgba(255, 49, 88, 0.15);
}

.bullet-list li {
  position: relative;
  padding-left: 18px;
  margin-bottom: 10px;
}

.bullet-list li:last-child {
  margin-bottom: 0;
}

.bullet-list li::after {
  position: absolute;
  content: "";
  height: 7px;
  width: 7px;
  background-color: #FF3158;
  border-radius: 50px;
  left: 0;
  top: 8px;
}

.bullet-list.bullet-list-dark li::after {
  background-color: #222222;
}

.tabs-navbar .nav-link.active, .tabs-navbar .nav-link:hover {
  color: #FF3158;
}

.tabs-navbar.nav-tabs .nav-item {
  margin-bottom: -1px;
  z-index: 2;
}

.tabs-navbar.nav-tabs .nav-link:hover,
.tabs-navbar.nav-tabs .active {
  border-bottom: 1px solid #FF3158 !important;
}

@media (max-width: 575px) {
  .tabs-navbar.nav-tabs .nav-link:hover,
  .tabs-navbar.nav-tabs .active {
    border-bottom: 0 !important;
    padding: 0 !important;
  }
}

@media (max-width: 575px) {
  .tabs-navbar.nav-tabs .nav-link {
    padding: 0px !important;
    margin-bottom: 15px;
  }
}

.form-control {
  height: 50px;
  border-color: #CECECE;
  border-radius: 5px;
  padding: 15px 20px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #888888;
}

.custom-checkbox {
  margin-top: 15px;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  background-color: #FF3158;
  border-color: #FF3158;
  box-shadow: none;
}

.custom-checkbox .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #FF3158;
  box-shadow: none;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(255, 49, 88, 0.5);
  border-color: rgba(255, 49, 88, 0.2);
  box-shadow: none;
}

.colored-icon.icon-1 {
  color: #FF3158;
}

.colored-icon.icon-2 {
  color: #FFBB33;
}

.colored-icon.icon-3 {
  color: #1E858B;
}

.colored-icon.icon-4 {
  color: #497CFF;
}

/*------------------------------------------------------------------
 # helper classes
-------------------------------------------------------------------*/
.font-primary {
  font-family: "Quicksand", sans-serif;
}

.rounded {
  border-radius: 5px;
}

.shadow {
  box-shadow: 0px 25px 65px rgba(0, 0, 0, 0.05) !important;
}

.border-top {
  border-top: 1px solid #E3E3E3 !important;
}

.border-bottom {
  border-bottom: 1px solid #E3E3E3 !important;
}

.border-left {
  border-left: 1px solid #E3E3E3 !important;
}

.border-right {
  border-right: 1px solid #E3E3E3 !important;
}

@media (max-width: 1199px) {
  .border-xl-0 {
    border: 0 !important;
  }
}

@media (max-width: 991px) {
  .border-lg-0 {
    border: 0 !important;
  }
}

@media (max-width: 767px) {
  .border-md-0 {
    border: 0 !important;
  }
}

@media (max-width: 575px) {
  .border-sm-0 {
    border: 0 !important;
  }
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.text-underline {
  text-decoration: 1px dashed;
}

a.text-underline:hover {
  text-decoration-style: solid;
}

.has-bg-brash {
  background-size: 1200px;
  background-repeat: repeat-x;
}

.no-bg-image {
  background-image: none !important;
}

.bg-brash-top {
  background-position: center top;
}

.bg-brash-bottom {
  background-position: center bottom;
}

.bg-brash-y {
  background-position: center top, center bottom;
}

.bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-light-gray {
  color: #F6F6F6;
}

.bg-light-gray {
  background-color: #F6F6F6;
}

.text-primary {
  color: #FF3158 !important;
}

a.text-primary:hover {
  color: #fd0030 !important;
}

.bg-primary {
  background-color: #FF3158 !important;
}

.text-primary {
  color: #FF3158 !important;
}

.bg-primary {
  background-color: rgba(255, 49, 88, 0.5) !important;
}

.bg-primary-fill {
  background-color: #FF3158 !important;
}

.text-secondary {
  color: rgba(255, 187, 51, 0.5) !important;
}

.bg-secondary {
  background-color: rgba(255, 187, 51, 0.5) !important;
}

.text-secondary-fill {
  color: #FFBB33 !important;
}

.bg-secondary-fill {
  background-color: #FFBB33 !important;
}

.text-tertiary {
  color: rgba(30, 133, 139, 0.5) !important;
}

.bg-tertiary {
  background-color: rgba(30, 133, 139, 0.5) !important;
}

.text-tertiary-fill {
  color: #1E858B !important;
}

.bg-tertiary-fill {
  background-color: #1E858B !important;
}

.bg-black {
  background-color: #000000;
}

.text-black {
  color: #000000;
}

.text-black-100 {
  color: #111111;
}

.bg-black-100 {
  background-color: #111111;
}

.bg-black-200 {
  background-color: #222222;
}

.text-black-200 {
  color: #222222;
}

.bg-black-300 {
  background-color: #333333;
}

.text-black-300 {
  color: #333333;
}

.text-black-400 {
  color: #444444;
}

.bg-black-400 {
  background-color: #444444;
}

.text-black-500 {
  color: #555555;
}

.bg-black-500 {
  background-color: #555555;
}

.text-black-600 {
  color: #666666;
}

.bg-black-600 {
  background-color: #666666;
}

.text-black-700 {
  color: #777777;
}

.bg-black-700 {
  background-color: #777777;
}

.text-black-800 {
  color: #888888;
}

.bg-black-800 {
  background-color: #888888;
}

.text-black-900 {
  color: #999999;
}

.bg-black-900 {
  background-color: #999999;
}

a.text-black-200:hover,
a.text-black-300:hover,
a.text-black-400:hover {
  color: #FF3158;
}

/*------------------------------------------------------------------
 # spacing classes
-------------------------------------------------------------------*/
.m-0 {
  margin: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.p-0 {
  padding: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.p-5 {
  padding: 5px;
}

.pt-5 {
  padding-top: 5px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pl-5 {
  padding-left: 5px;
}

.pr-5 {
  padding-right: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.p-10 {
  padding: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-10 {
  padding-left: 10px;
}

.pr-10 {
  padding-right: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.p-15 {
  padding: 15px;
}

.pt-15 {
  padding-top: 15px;
}

.pb-15 {
  padding-bottom: 15px;
}

.pl-15 {
  padding-left: 15px;
}

.pr-15 {
  padding-right: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.p-20 {
  padding: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pl-20 {
  padding-left: 20px;
}

.pr-20 {
  padding-right: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.p-25 {
  padding: 25px;
}

.pt-25 {
  padding-top: 25px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pl-25 {
  padding-left: 25px;
}

.pr-25 {
  padding-right: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.p-30 {
  padding: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pl-30 {
  padding-left: 30px;
}

.pr-30 {
  padding-right: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.p-35 {
  padding: 35px;
}

.pt-35 {
  padding-top: 35px;
}

.pb-35 {
  padding-bottom: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.p-40 {
  padding: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pl-40 {
  padding-left: 40px;
}

.pr-40 {
  padding-right: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.p-45 {
  padding: 45px;
}

.pt-45 {
  padding-top: 45px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pl-45 {
  padding-left: 45px;
}

.pr-45 {
  padding-right: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.p-50 {
  padding: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pl-50 {
  padding-left: 50px;
}

.pr-50 {
  padding-right: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.p-55 {
  padding: 55px;
}

.pt-55 {
  padding-top: 55px;
}

.pb-55 {
  padding-bottom: 55px;
}

.pl-55 {
  padding-left: 55px;
}

.pr-55 {
  padding-right: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.p-60 {
  padding: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pl-60 {
  padding-left: 60px;
}

.pr-60 {
  padding-right: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.p-65 {
  padding: 65px;
}

.pt-65 {
  padding-top: 65px;
}

.pb-65 {
  padding-bottom: 65px;
}

.pl-65 {
  padding-left: 65px;
}

.pr-65 {
  padding-right: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.p-70 {
  padding: 70px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pl-70 {
  padding-left: 70px;
}

.pr-70 {
  padding-right: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.p-75 {
  padding: 75px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pl-75 {
  padding-left: 75px;
}

.pr-75 {
  padding-right: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.p-80 {
  padding: 80px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pl-80 {
  padding-left: 80px;
}

.pr-80 {
  padding-right: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.p-85 {
  padding: 85px;
}

.pt-85 {
  padding-top: 85px;
}

.pb-85 {
  padding-bottom: 85px;
}

.pl-85 {
  padding-left: 85px;
}

.pr-85 {
  padding-right: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.p-90 {
  padding: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pl-90 {
  padding-left: 90px;
}

.pr-90 {
  padding-right: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.p-95 {
  padding: 95px;
}

.pt-95 {
  padding-top: 95px;
}

.pb-95 {
  padding-bottom: 95px;
}

.pl-95 {
  padding-left: 95px;
}

.pr-95 {
  padding-right: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.p-100 {
  padding: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pl-100 {
  padding-left: 100px;
}

.pr-100 {
  padding-right: 100px;
}

.m-105 {
  margin: 105px;
}

.mt-105 {
  margin-top: 105px;
}

.mb-105 {
  margin-bottom: 105px;
}

.ml-105 {
  margin-left: 105px;
}

.mr-105 {
  margin-right: 105px;
}

.p-105 {
  padding: 105px;
}

.pt-105 {
  padding-top: 105px;
}

.pb-105 {
  padding-bottom: 105px;
}

.pl-105 {
  padding-left: 105px;
}

.pr-105 {
  padding-right: 105px;
}

.m-110 {
  margin: 110px;
}

.mt-110 {
  margin-top: 110px;
}

.mb-110 {
  margin-bottom: 110px;
}

.ml-110 {
  margin-left: 110px;
}

.mr-110 {
  margin-right: 110px;
}

.p-110 {
  padding: 110px;
}

.pt-110 {
  padding-top: 110px;
}

.pb-110 {
  padding-bottom: 110px;
}

.pl-110 {
  padding-left: 110px;
}

.pr-110 {
  padding-right: 110px;
}

.m-115 {
  margin: 115px;
}

.mt-115 {
  margin-top: 115px;
}

.mb-115 {
  margin-bottom: 115px;
}

.ml-115 {
  margin-left: 115px;
}

.mr-115 {
  margin-right: 115px;
}

.p-115 {
  padding: 115px;
}

.pt-115 {
  padding-top: 115px;
}

.pb-115 {
  padding-bottom: 115px;
}

.pl-115 {
  padding-left: 115px;
}

.pr-115 {
  padding-right: 115px;
}

.m-120 {
  margin: 120px;
}

.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.ml-120 {
  margin-left: 120px;
}

.mr-120 {
  margin-right: 120px;
}

.p-120 {
  padding: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pl-120 {
  padding-left: 120px;
}

.pr-120 {
  padding-right: 120px;
}

.content a {
  color: #333333;
}

.content a:hover {
  color: #FF3158;
}

.content.content-active a {
  color: #FF3158;
}

.content.has-underdot-link a {
  border-bottom: 1px dashed;
}

.content.has-underdot-link a:hover {
  border-bottom-style: solid;
  text-decoration: none;
}

.btn {
  font-size: 15px;
  padding: 14px 27px;
  color: #ffffff;
  border-radius: 5px;
  border: 0;
  font-weight: 500;
  transition: .3s;
  position: relative;
}

.btn:active, .btn:focus {
  border: 0;
  box-shadow: none !important;
  border-color: #FF3158 !important;
}

.btn.btn-sm {
  padding: 10px 25px;
}

.btn:hover {
  transform: translateY(-3px);
}

.btn:hover .icon {
  background-color: #f3002e;
}

.btn:hover .icon svg {
  transform: translateX(3px);
}

.btn.has-icon {
  padding: 14px 20px;
  padding-right: 60px;
}

.btn.has-icon .icon {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f1274d;
  height: 100%;
  width: 42px;
  padding: 0 8px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: 0.3s;
  overflow: hidden;
}

.btn.has-icon .icon svg {
  height: 100%;
  width: 27px;
  margin-left: -1px;
  transition: 0.3s;
}

.btn.btn-primary {
  background-color: #FF3158 !important;
}

.btn.btn-primary:hover, .btn.btn-primary:active, .btn.btn-primary:focus {
  color: #ffffff;
  background-color: #ff1843 !important;
}

.btn.btn-primary:hover {
  box-shadow: 0px 15px 35px rgba(255, 49, 88, 0.3);
}

.btn.btn-outline-primary {
  color: #222222;
  background-color: transparent;
  border: 0.094rem solid #FF3158;
}

.btn.btn-outline-primary:active, .btn.btn-outline-primary:hover, .btn.btn-outline-primary:focus {
  color: #ffffff;
  background-color: #FF3158 !important;
}

.btn.btn-outline-primary:hover {
  box-shadow: 0px 15px 35px rgba(255, 49, 88, 0.3);
}

.btn.btn-secondary {
  background-color: #9b9b9b !important;
}

.btn.btn-secondary:hover, .btn.btn-secondary:active, .btn.btn-secondary:focus {
  color: #ffffff;
  background-color: #686868 !important;
}

.btn.btn-outline-secondary {
  color: #222222;
  background-color: transparent;
  border: 0.094rem solid #CECECE;
}

.btn.btn-outline-secondary:active, .btn.btn-outline-secondary:hover, .btn.btn-outline-secondary:focus {
  color: #ffffff;
  border-color: rgba(30, 133, 139, 0.5) !important;
  background-color: #9b9b9b !important;
}

.btn.btn-link {
  color: #222222;
  background-color: transparent;
  border: 0;
}

.btn.btn-link svg {
  transition: transform .3s;
}

.btn.btn-link:active, .btn.btn-link:hover, .btn.btn-link:focus {
  color: #FF3158;
  text-decoration: none;
}

.btn.btn-link:active svg, .btn.btn-link:hover svg, .btn.btn-link:focus svg {
  transform: translateX(2px);
}

.btn.btn-link.text-primary:hover {
  color: #ca0026 !important;
}

/*------------------------------------------------------------------
 # header style
-------------------------------------------------------------------*/
.header-nav {
  z-index: 500;
}

.header-nav .navbar {
  padding-top: 25px;
  padding-bottom: 25px;
}

.header-nav .nav-item .nav-link {
  font-weight: 600;
  font-size: 15px;
  color: #222222 !important;
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.header-nav .nav-item:hover .nav-link, .header-nav .nav-item.active .nav-link {
  color: #FF3158 !important;
}

@media (min-width: 1200px) {
  .navbar .dropdown-menu {
    display: block;
    height: 0;
    overflow: hidden;
    width: 220px;
    transition: 0.2s;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0;
    background-color: transparent;
    z-index: 1;
  }
  .navbar .dropdown-menu::after {
    position: absolute;
    content: "";
    height: 0;
    width: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0;
    z-index: -1;
    transition: .3s ease-out;
    box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1);
    transition-delay: padding .25s;
  }
  .navbar .dropdown-menu li {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-5px);
  }
  .navbar .dropdown-menu li:first-child {
    margin-top: 15px;
  }
  .navbar .dropdown-menu li:last-child {
    margin-bottom: 15px;
  }
  .navbar .dropdown:hover .dropdown-menu {
    height: auto;
    overflow: visible;
  }
  .navbar .dropdown:hover .dropdown-menu::after {
    height: 100%;
  }
  .navbar .dropdown:hover .dropdown-menu li {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    transition: 0.25s;
    transition-delay: .2s;
  }
  .navbar .sub-dropdown {
    position: relative;
  }
  .navbar .sub-dropdown .dropdown-submenu {
    position: absolute;
    display: block;
    z-index: 2;
    width: 90%;
    left: 100%;
    top: -10px;
    height: 0;
    overflow: hidden;
    transition: .3s;
    background-color: transparent;
  }
  .navbar .sub-dropdown .dropdown-submenu::after {
    position: absolute;
    content: "";
    height: 0;
    width: 100%;
    background-color: #ffffff;
    top: 0;
    left: 0;
    z-index: -1;
    transition: .3s ease-out;
    box-shadow: 0 10px 30px rgba(22, 28, 45, 0.1);
    transition-delay: padding .25s;
  }
  .navbar .sub-dropdown .dropdown-submenu li {
    opacity: 0 !important;
    visibility: hidden !important;
    transform: translateY(-5px) !important;
    transition: 0s !important;
    transition-delay: 0s !important;
  }
  .navbar .sub-dropdown .dropdown-submenu li:first-child {
    margin-top: 10px;
  }
  .navbar .sub-dropdown .dropdown-submenu li:last-child {
    margin-bottom: 10px;
  }
  .navbar .sub-dropdown:hover .dropdown-submenu {
    height: auto;
    overflow: visible;
  }
  .navbar .sub-dropdown:hover .dropdown-submenu::after {
    height: 100%;
  }
  .navbar .sub-dropdown:hover .dropdown-submenu li {
    opacity: 1 !important;
    visibility: visible !important;
    transform: translateY(0px) !important;
    transition: 0.25s !important;
    transition-delay: .2s !important;
  }
}

.dropdown-menu {
  border: 0;
  margin-top: 0;
  padding: 0 8px;
}

.dropdown-submenu {
  list-style: none;
  display: none;
  padding: 0 8px;
  background-color: #ffffff;
}

.dropdown-item {
  padding: 6px 12px;
  font-weight: 600;
  font-size: 15px;
}

.sub-dropdown.active,
.sub-dropdown.active > .dropdown-item,
.sub-dropdown:hover > .dropdown-item,
.dropdown-item.active,
.dropdown-item:active,
.dropdown-item:focus,
.dropdown-item:hover {
  color: #FF3158;
  background-color: rgba(255, 49, 88, 0.08);
  border-radius: 5px;
}

.sub-dropdown.active > .dropdown-item {
  background-color: rgba(255, 49, 88, 0.02);
}

.dropdown-toggle::after {
  display: none;
}

@media (max-width: 1199px) {
  .dropdown-menu {
    border: 0;
    padding: 0;
    text-align: left !important;
    background: rgba(255, 49, 88, 0.05);
    border-radius: 6px;
  }
  .navbar .navbar-nav {
    margin-top: 15px;
    width: 280px;
    max-width: 100%;
    margin-right: auto;
    align-items: initial !important;
  }
  .navbar .nav-item {
    display: block;
    text-align: left;
  }
  .navbar .nav-link {
    padding: 8px 4px !important;
  }
  .navbar .dropdown-menu li:first-child {
    padding-top: 10px;
  }
  .navbar .dropdown-menu li:last-child {
    padding-bottom: 10px;
  }
  .navbar-collapse {
    position: fixed;
    background-color: #ffffff;
    width: calc(100% - 30px);
    height: auto;
    max-height: calc(100% - 100px);
    z-index: 9999;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.1);
    padding: 5px 20px 28px;
    text-align: center;
    top: 90px;
    left: 15px;
  }
  .navbar-collapse.collapsing,
  .navbar-collapse.show {
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    transition-property: opacity, transform, -webkit-transform;
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  .navbar-collapse.show {
    overflow-x: hidden;
    overflow-y: auto;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .navbar-collapse.collapsing {
    opacity: 0;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  .navbar-button {
    width: 280px;
    max-width: 100%;
    margin: 15px auto 0;
  }
  .dropdown-item {
    padding: 6px 18px;
  }
}

.navbar-toggler .nav-toggle-icon {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform .4s;
  user-select: none;
}

.navbar-toggler .nav-toggle-icon .top {
  stroke-dasharray: 40 121;
}

.navbar-toggler .nav-toggle-icon .bottom {
  stroke-dasharray: 40 121;
}

.navbar-toggler .nav-toggle-icon.active {
  transform: rotate(45deg);
}

.navbar-toggler .nav-toggle-icon.active .top {
  stroke-dashoffset: -68px;
}

.navbar-toggler .nav-toggle-icon.active .bottom {
  stroke-dashoffset: -68px;
}

.navbar-toggler .nav-toggle-icon .line {
  fill: none;
  transition: stroke-dasharray .4s, stroke-dashoffset .4s;
  stroke: #333333;
  stroke-width: 5.5;
  stroke-linecap: round;
}

.banner {
  padding-top: 75px;
  padding-bottom: 160px;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  .banner {
    padding-top: 40px;
  }
}

.banner .input-group .form-control, .banner .input-group button {
  border-radius: 5px !important;
}

@media (max-width: 575px) {
  .banner .input-group .form-control {
    width: 100%;
    margin-bottom: 10px;
  }
  .banner .input-group button {
    margin-left: 0 !important;
    height: 50px;
    width: 100%;
  }
}

.banner .btn-outline-secondary:active, .banner .btn-outline-secondary:hover, .banner .btn-outline-secondary:focus {
  color: #666666 !important;
  border-color: rgba(30, 133, 139, 0.5) !important;
  background-color: transparent !important;
}

.banner .banner-image {
  margin-left: 50px;
}

@media (max-width: 991px) {
  .banner .banner-image {
    margin-left: 0;
  }
}

.banner .banner-image.has-shapes .shape-sm {
  left: 42%;
  top: 40px;
}

@media (max-width: 767px) {
  .banner .banner-image.has-shapes .shape-sm {
    display: none;
  }
}

.banner .banner-image.has-shapes .shape-md {
  right: 7px;
  bottom: 30px;
}

@media (max-width: 767px) {
  .banner .banner-image.has-shapes .shape-md {
    display: none;
  }
}

.banner .banner-image.has-shapes .shape-lg {
  left: -10px;
  bottom: 20px;
}

@media (max-width: 767px) {
  .banner .banner-image.has-shapes .shape-lg {
    display: none;
  }
}

.banner .shape-1 {
  top: 15%;
  left: 20px;
}

.banner .shape-3 {
  bottom: 150px;
  left: 15px;
}

.banner .shape-5 {
  left: 32%;
  bottom: 100px;
}

.banner .shape-6 {
  bottom: 130px;
  right: 30px;
}

.banner .shape-4 {
  top: 50px;
  right: 20px;
}

.banner .shape-8 {
  top: 130px;
  left: 35%;
}

.banner .shape-7 {
  top: 80px;
  right: 40%;
}

.page-header {
  height: 255px;
  padding-bottom: 45px;
}

.page-header .shape-1 {
  left: 100px;
  top: 15px;
  height: 18px;
  width: 18px;
}

.page-header .shape-2 {
  left: 25%;
  top: 50px;
  height: 12px;
  width: 12px;
  opacity: .5;
}

.page-header .shape-3 {
  left: 15%;
  bottom: 40%;
}

.page-header .shape-4 {
  left: 32%;
  bottom: 45%;
  height: 22px;
  width: 22px;
}

.page-header .shape-5 {
  right: 35%;
  top: 30px;
  height: 18px;
  width: 18px;
}

.page-header .shape-6 {
  right: 8%;
  top: 50px;
}

@media (max-width: 767px) {
  .page-header .shape-6 {
    display: none;
  }
}

.page-header .shape-7 {
  top: 15px;
  right: 19%;
  height: 9px;
  width: 9px;
  opacity: .7;
}

.page-header .shape-8 {
  bottom: 100px;
  right: 25%;
}

.has-video-popup {
  position: relative;
}

.has-video-popup .video-play-btn {
  border: 0;
  outline: 0;
  height: 70px;
  width: 70px;
  padding: 2px 0 0 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  background-color: #FF3158;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  -o-transition: 0.3s;
  box-shadow: 0 0 25px #ffffff;
}

@media (max-width: 767px) {
  .has-video-popup .video-play-btn {
    height: 60px;
    width: 59px;
  }
}

@media (max-width: 480px) {
  .has-video-popup .video-play-btn {
    height: 45px;
    width: 44px;
  }
  .has-video-popup .video-play-btn svg {
    height: 1.4em;
    width: 1.4em;
    margin-top: -4px;
  }
}

.has-video-popup .video-play-btn:hover {
  transform: translate(-50%, -50%) scale(1.2);
}

.has-video-popup .video-play-btn::after, .has-video-popup .video-play-btn::before {
  content: "";
  position: absolute;
  border-radius: 50%;
  border: 6px solid rgba(255, 49, 88, 0.5);
  left: -20px;
  top: -20px;
  right: -20px;
  bottom: -20px;
  opacity: 0;
  backface-visibility: hidden;
  animation: pulse 2s linear infinite;
}

.has-video-popup .video-play-btn::after {
  animation-delay: .5s;
}

@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

#videoModal .modal-dialog {
  max-width: 800px;
  margin: 30px auto;
}

#videoModal .modal-body {
  position: relative;
  padding: 0px;
}

#videoModal .close {
  position: absolute;
  right: 15px;
  top: -40px;
  z-index: 999;
  font-size: 2rem;
  font-weight: normal;
  color: #fff;
  opacity: 1;
}

.brand-carousel .slick-list {
  margin-left: -17px;
}

.brand-carousel .brand-item {
  height: 45px;
  margin-left: 17px;
}

.brand-carousel .brand-item img {
  z-index: -1;
  transition: .3s ease-out;
  opacity: .5;
  filter: grayscale(1);
}

.brand-carousel .brand-item:hover img {
  opacity: 1;
  filter: grayscale(0);
}

.how-it-works-tab .nav-item:first-child .nav-link::after,
.how-it-works-tab .nav-item:first-child .nav-link::before {
  display: none;
}

.how-it-works-tab .nav-link {
  font-weight: 600;
  position: relative;
  padding-top: 0;
  min-width: 180px;
  text-align: center;
  z-index: 1;
  color: #333333;
}

@media (max-width: 991px) {
  .how-it-works-tab .nav-link {
    min-width: 160px;
    max-width: 100%;
  }
}

.how-it-works-tab .nav-link::before,
.how-it-works-tab .nav-link .circle-shape::after,
.how-it-works-tab .nav-link .circle-shape::before {
  position: absolute;
  content: '';
}

.how-it-works-tab .nav-link .circle-shape {
  position: relative;
  display: block;
  height: 32px;
  width: 32px;
  background-color: #CECECE;
  border-radius: 50px;
  margin: auto;
  margin-bottom: 10px;
  overflow: hidden;
  z-index: 2;
}

.how-it-works-tab .nav-link .circle-shape::before {
  background-color: #FF3158;
  height: 32px;
  width: 32px;
  top: 0;
  right: 0;
  border-radius: 50px;
  transition: .2s ease-out;
  transform: scale(0);
}

.how-it-works-tab .nav-link .circle-shape::after {
  background-color: #ffffff;
  height: 16px;
  width: 16px;
  top: 8px;
  left: calc(50% - 8px);
  z-index: 1;
  border-radius: 50px;
}

.how-it-works-tab .nav-link::before {
  width: 100%;
  height: 2px;
  background-color: #F7F7F7;
  left: calc(-50% + 16px);
  top: 16px;
  border-radius: 50px;
  transition: .3s;
  z-index: -1;
}

@media (max-width: 767px) {
  .how-it-works-tab .nav-link::before {
    display: none;
  }
}

.how-it-works-tab .nav-link.active {
  background-color: transparent;
  color: #FF3158;
}

.how-it-works-tab .nav-link.active .circle-shape::before {
  transform: scale(1);
}

.how-it-works-tab .tab-pane.active .anim-to-top {
  animation: anim-to-top .7s;
}

.how-it-works-tab .tab-pane.active .anim-to-bottom {
  animation: anim-to-bottom .7s;
}

@keyframes anim-to-top {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes anim-to-bottom {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.growth-rate-counter h2 {
  font-size: 32px;
}

.growth-rate-counter .block {
  width: 110px;
}

.growth-rate-counter .block-sm {
  width: 90px;
}

@media (max-width: 991px) {
  .growth-rate-counter .block,
  .growth-rate-counter .block-sm {
    width: 20% !important;
  }
}

@media (max-width: 575px) {
  .growth-rate-counter .block,
  .growth-rate-counter .block-sm {
    width: auto !important;
  }
}

.has-colored-text > div:nth-child(1) .has-text-color {
  color: #FFBB33;
}

.has-colored-text > div:nth-child(2) .has-text-color {
  color: #FF3158;
}

.has-colored-text > div:nth-child(3) .has-text-color {
  color: #1E858B;
}

.has-colored-text > div:nth-child(4) .has-text-color {
  color: #FF3158;
}

.has-colored-text > div:nth-child(5) .has-text-color {
  color: #1E858B;
}

.has-colored-text > div:nth-child(6) .has-text-color {
  color: #FFBB33;
}

.colored-icon-box .icon-box {
  transition: 0.3s;
  position: relative;
  z-index: 1;
}

.colored-icon-box .icon-box::after {
  position: absolute;
  content: "";
  height: 0;
  width: 100%;
  border-radius: 5px;
  z-index: -1;
  background-color: #FAFAFA;
  bottom: 0;
  left: 0;
  transition: 0.3s;
}

.colored-icon-box .icon-box .icon {
  font-size: 55px;
  display: inline-block;
  margin-bottom: 25px;
}

.colored-icon-box .icon-box h4 {
  transition: color .3s;
}

.colored-icon-box .icon-box:hover {
  box-shadow: none !important;
  transform: translateY(2px);
}

.colored-icon-box .icon-box:hover::after {
  height: 100%;
}

.colored-icon-box > div:nth-child(1) .icon-box .icon,
.colored-icon-box > div:nth-child(5) .icon-box .icon {
  color: #FF3158;
}

.colored-icon-box > div:nth-child(1) .icon-box:hover::after,
.colored-icon-box > div:nth-child(5) .icon-box:hover::after {
  background-color: rgba(255, 49, 88, 0.05);
}

.colored-icon-box > div:nth-child(1) .icon-box:hover h4,
.colored-icon-box > div:nth-child(5) .icon-box:hover h4 {
  color: #FF3158;
}

.colored-icon-box > div:nth-child(2) .icon-box .icon,
.colored-icon-box > div:nth-child(6) .icon-box .icon {
  color: #1E858B;
}

.colored-icon-box > div:nth-child(2) .icon-box:hover::after,
.colored-icon-box > div:nth-child(6) .icon-box:hover::after {
  background-color: rgba(30, 133, 139, 0.05);
}

.colored-icon-box > div:nth-child(2) .icon-box:hover h4,
.colored-icon-box > div:nth-child(6) .icon-box:hover h4 {
  color: #1E858B;
}

.colored-icon-box > div:nth-child(3) .icon-box .icon,
.colored-icon-box > div:nth-child(7) .icon-box .icon {
  color: #FFBB33;
}

.colored-icon-box > div:nth-child(3) .icon-box:hover::after,
.colored-icon-box > div:nth-child(7) .icon-box:hover::after {
  background-color: rgba(255, 187, 51, 0.1);
}

.colored-icon-box > div:nth-child(3) .icon-box:hover h4,
.colored-icon-box > div:nth-child(7) .icon-box:hover h4 {
  color: #FFBB33;
}

.colored-icon-box > div:nth-child(4) .icon-box .icon,
.colored-icon-box > div:nth-child(8) .icon-box .icon {
  color: #21435B;
}

.colored-icon-box > div:nth-child(4) .icon-box:hover::after,
.colored-icon-box > div:nth-child(8) .icon-box:hover::after {
  background-color: rgba(33, 67, 91, 0.05);
}

.colored-icon-box > div:nth-child(4) .icon-box:hover h4,
.colored-icon-box > div:nth-child(8) .icon-box:hover h4 {
  color: #21435B;
}

.image-info-section .has-shapes .shape-1 {
  bottom: 45px;
  left: 40%;
}

@media (max-width: 991px) {
  .image-info-section .has-shapes .shape-1 {
    bottom: 20px;
  }
}

.image-info-section .has-shapes .shape-2 {
  top: 50px;
  right: 20px;
}

.image-info-section .shape-3 {
  top: 210px;
  left: 70px;
}

.image-info-section .shape-4 {
  bottom: 150px;
  left: 20px;
}

@media (max-width: 767px) {
  .image-info-section .shape-4 {
    display: none;
  }
}

.image-info-section .shape-5 {
  top: 100px;
  right: 40px;
}

@media (max-width: 767px) {
  .image-info-section .shape-5 {
    display: none;
  }
}

.image-info-section .shape-6 {
  bottom: 30%;
  right: 40px;
}

.image-info-section .shape-7 {
  bottom: 100px;
  right: 20%;
}

.testimonials-section .slick-list {
  margin-left: -30px;
}

.testimonials-section .testimonials-carousel .testimonials-item {
  margin-left: 30px;
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(1) .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(2) .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(3) .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(4) .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(5) .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(6) .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(7) .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(8) .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(9) .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(10) .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(11) .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-carousel .testimonials-item:nth-child(12) .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(1) .testimonials-item .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(2) .testimonials-item .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(3) .testimonials-item .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(4) .testimonials-item .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(5) .testimonials-item .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(6) .testimonials-item .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(7) .testimonials-item .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(8) .testimonials-item .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(9) .testimonials-item .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(10) .testimonials-item .icon {
  color: rgba(255, 187, 51, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(11) .testimonials-item .icon {
  color: rgba(30, 133, 139, 0.6);
}

.testimonials-section .testimonials-items > div:nth-child(12) .testimonials-item .icon {
  color: rgba(255, 49, 88, 0.6);
}

.testimonials-section .testimonials-item {
  padding: 0;
  position: relative;
  margin-bottom: 30px;
}

.testimonials-section .testimonials-item .block {
  position: relative;
  padding: 30px;
  padding-bottom: 25px;
}

.testimonials-section .testimonials-item .block p {
  line-height: 1.5;
}

.testimonials-section .testimonials-item .block img {
  position: absolute;
  left: 20px;
  bottom: -14px;
  width: 32px;
  height: 14px;
}

.testimonials-section .testimonials-item .user-info {
  padding-left: 7px;
}

.testimonials-section .testimonials-item .user-info img {
  height: 60px;
  width: 60px;
}

.testimonials-section .testimonials-item .border-bottom {
  padding-bottom: 20px;
}

.testimonials-section .testimonials-item .border-bottom::after {
  display: none;
}

.testimonials-section .slick-dots li button:before {
  display: none;
}

.testimonials-section .slick-dots {
  bottom: -45px;
  z-index: 2;
}

.testimonials-section .slick-dots li button {
  position: relative;
  height: 25px;
  width: 25px;
}

.testimonials-section .slick-dots li button::after {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  border: 1px solid #000000;
  border-radius: 50px;
  left: calc(50% - 7px);
  top: calc(50% - 7px);
  transform: scale(0.5);
  transition: .3s;
}

.testimonials-section .slick-dots li.slick-active button::after {
  border-color: #FF3158;
  background-color: #FF3158;
  transform: scale(1);
}

.testimonials-section .shape-1 {
  top: 125px;
  left: 75px;
  z-index: 2;
}

.testimonials-section .shape-2 {
  top: 125px;
  right: 75px;
  z-index: 2;
}

.testimonials-section .shape-3 {
  left: 15px;
  top: 50%;
  height: 41px;
  width: 41px;
}

.testimonials-section .shape-4 {
  right: 15px;
  top: 45%;
  height: 41px;
  width: 41px;
}

.testimonials-section .shape-5 {
  bottom: 180px;
  left: 80px;
}

.testimonials-section .shape-6 {
  bottom: 196px;
  right: 119px;
  height: 15px;
  width: 15px;
}

.tools-use-section .inCircle-tools {
  padding: 180px 0;
}

.tools-use-section .inCircle-tools li img {
  max-width: 100%;
}

.tools-use-section .inCircle-tools .has-centered {
  z-index: 99;
}

.tools-use-section .inCircle-tools .lg {
  height: 180px;
  width: 180px;
}

.tools-use-section .inCircle-tools .md {
  height: 114px;
  width: 114px;
}

.tools-use-section .inCircle-tools .sm {
  height: 82px;
  width: 82px;
}

.tools-use-section .inCircle-tools .xs {
  height: 72px;
  width: 72px;
}

@media (max-width: 575px) {
  .tools-use-section .inCircle-tools {
    padding: 0;
    text-align: center;
    margin: 40px 0;
  }
  .tools-use-section .inCircle-tools li {
    position: static !important;
    height: 114px !important;
    width: 114px !important;
    padding: 0 15px;
    margin: 10px;
  }
}

.tools-use-section .shape-1 {
  top: 37%;
  left: 6%;
}

.tools-use-section .shape-2 {
  bottom: 125px;
  left: 75px;
}

.tools-use-section .shape-3 {
  left: 40%;
  top: 100px;
}

.tools-use-section .shape-4 {
  left: 40%;
  bottom: 120px;
}

.tools-use-section .shape-5 {
  top: 150px;
  right: 80px;
  height: 22px;
  width: 22px;
}

.tools-use-section .shape-6 {
  bottom: 100px;
  right: 119px;
  height: 61px;
  width: 61px;
}

.team-filters .btn {
  margin: 0 14px;
  color: #FF3158;
  border: 2px solid rgba(255, 49, 88, 0.8);
  text-transform: capitalize;
}

.team-filters .btn:active {
  border-color: transparent !important;
}

.team-filters .btn:hover, .team-filters .btn.active {
  color: #ffffff;
  background-color: rgba(255, 49, 88, 0.8);
}

.team-item .team-image .team-shapes {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 2s linear;
}

.team-item .team-image .shape-1 {
  left: -13px;
  top: calc(50% - 13px);
  z-index: 1;
}

.team-item .team-image .shape-2 {
  right: 32px;
  top: 0;
  z-index: 1;
  height: 45px;
  width: 45px;
  z-index: 1;
}

.team-item .team-image .shape-3 {
  right: 19px;
  bottom: 20px;
  z-index: 1;
}

.team-item:hover .team-shapes {
  transform: rotateZ(360deg);
  transition: transform 20s linear;
}

.pricing-switch label {
  cursor: text;
  user-select: none;
  padding: 0 15px;
}

.pricing-switch .toggle {
  height: 34px;
  cursor: pointer;
  z-index: 1;
  position: relative;
}

.pricing-switch .toggle .switch {
  display: inline-block;
  height: 100%;
  width: 70px;
  border: 1px solid #999999;
  border-radius: 50px;
  position: relative;
}

.pricing-switch .toggle .switch::after {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 50px;
  background-color: #FF3158;
  left: 5px;
  top: 4px;
  z-index: 2;
  transition: 0.25s ease-in-out;
}

.pricing-switch .toggle .pricing-check:checked ~ .switch::after {
  left: 57.5%;
}

.pricing-switch .toggle .pricing-check:active ~ .switch::after {
  transform: scale(0.8);
}

.pricing-switch .toggle .pricing-check {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 3;
  opacity: 0;
  cursor: pointer;
}

@media (max-width: 575px) {
  .pricing-switch {
    text-align: center;
  }
}

.pricing-table td.active {
  position: relative;
  overflow: hidden;
}

.pricing-table td.active .recomended {
  position: absolute;
  background: #dca71c;
  color: #ffffff;
  font-weight: 600;
  padding: 0 25px;
  transform: rotate(45deg);
  right: -29px;
  top: 26px;
  font-size: 10px;
  letter-spacing: 1px;
}

.pricing-table td, .pricing-table th {
  border: 1px solid #EFEFEF;
  padding: 20px;
  width: 20%;
}

.pricing-table td span {
  vertical-align: middle;
}

.pricing-table thead td, .pricing-table thead th {
  border-bottom-width: 1px;
  background: #FF3158;
  border-color: #FF5F7D;
}

.pricing-table thead td:first-child, .pricing-table thead th:first-child {
  border-top-color: transparent;
  border-left-color: transparent;
  border-top-left-radius: 25px;
}

.pricing-table thead td:last-child, .pricing-table thead th:last-child {
  border-top-color: transparent;
  border-right-color: transparent;
  border-top-right-radius: 25px;
}

@media (max-width: 991px) {
  .pricing-table {
    display: block;
  }
  .pricing-table thead, .pricing-table tbody, .pricing-table th, .pricing-table td, .pricing-table tr {
    display: block;
  }
  .pricing-table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .pricing-table tr {
    border: 1px solid #ccc;
  }
  .pricing-table td, .pricing-table th {
    padding: 10px 20px;
    width: initial;
  }
  .pricing-table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  .pricing-table td:before {
    position: absolute;
    top: 10px;
    left: 20px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
    content: attr(data-label);
  }
}

@media (max-width: 575px) {
  .pricing-table td {
    padding-left: 40%;
  }
  .pricing-table td:before {
    width: 39%;
  }
}

.accordionFAQ .card-header {
  position: relative;
  padding-left: 40px;
  cursor: pointer;
  user-select: none;
}

@media (max-width: 767px) {
  .accordionFAQ .card-header {
    padding-left: 30px;
  }
}

.accordionFAQ .card-header h4 {
  transition: 0.3s;
}

.accordionFAQ .card-header h4 svg {
  color: #A1A1A1;
}

.accordionFAQ .card-header .accordion-arrow {
  position: absolute;
  left: 0;
  transform: rotate(-90deg);
  transition: 0.3s;
  font-size: 16px;
  margin-top: 5px;
}

.accordionFAQ .card-header [aria-expanded="true"] {
  color: #FF3158;
}

.accordionFAQ .card-header [aria-expanded="true"] .accordion-arrow {
  transform: rotate(0);
  margin-top: 4px;
  color: #FF3158;
}

.horizontal-accordion .shape-1 {
  top: 125px;
  left: 75px;
}

.horizontal-accordion .shape-2 {
  top: 125px;
  right: 75px;
}

.horizontal-accordion .shape-3 {
  left: 15px;
  top: 50%;
  height: 41px;
  width: 41px;
}

.horizontal-accordion .shape-4 {
  right: 15px;
  top: 45%;
  height: 41px;
  width: 41px;
}

.horizontal-accordion .shape-5 {
  bottom: 180px;
  left: 80px;
}

.horizontal-accordion .shape-6 {
  bottom: 196px;
  right: 59px;
  height: 15px;
  width: 15px;
}

.life-at-gallery .shape-1 {
  top: 150px;
  left: 7%;
  height: 20px;
  width: 20px;
}

.life-at-gallery .shape-2 {
  top: 35%;
  left: 140px;
}

.life-at-gallery .shape-3 {
  top: 55%;
  left: 100px;
}

.life-at-gallery .shape-4 {
  bottom: 150px;
  left: 130px;
  height: 20px;
  width: 20px;
}

.life-at-gallery .shape-5 {
  top: 150px;
  right: 130px;
  height: 20px;
  width: 20px;
}

.life-at-gallery .shape-6 {
  bottom: 55%;
  right: 100px;
}

.life-at-gallery .shape-7 {
  bottom: 35%;
  right: 140px;
}

.life-at-gallery .shape-8 {
  bottom: 150px;
  right: 7%;
  height: 20px;
  width: 20px;
}

.join-team-section .shape-1 {
  top: 150px;
  left: 7%;
  height: 20px;
  width: 20px;
}

.join-team-section .shape-2 {
  top: 35%;
  left: 140px;
}

.join-team-section .shape-3 {
  top: 55%;
  left: 50px;
}

.join-team-section .shape-4 {
  bottom: 150px;
  left: 130px;
  height: 20px;
  width: 20px;
}

.join-team-section .shape-5 {
  top: 150px;
  right: 15%;
  height: 20px;
  width: 20px;
}

.join-team-section .shape-6 {
  bottom: 55%;
  right: 100px;
}

.join-team-section .shape-7 {
  bottom: 35%;
  right: 140px;
}

.join-team-section .shape-8 {
  bottom: 150px;
  right: 7%;
  height: 20px;
  width: 20px;
}

.modal.fade .modal-dialog {
  transform: translateX(0) scale(0.95);
}

.modal.show .modal-dialog {
  transform: translate(0) scale(1);
}

.modal-backdrop {
  background-color: #010e1d;
}

.modal-backdrop.show {
  opacity: .6;
}

.tabs-navbar.position-sticky {
  top: 0;
}

@media (max-width: 575px) {
  .tabs-navbar:not(.position-sticky) .nav-link,
  .tabs-navbar:not(.position-sticky) .nav-link:hover {
    padding: 10px 0 !important;
    border-radius: 5px;
  }
  .tabs-navbar:not(.position-sticky) .nav-link.active,
  .tabs-navbar:not(.position-sticky) .nav-link:hover.active {
    padding: 10px 15px !important;
    background-color: rgba(255, 49, 88, 0.1);
  }
}

.service-box .block {
  border: 1px solid #E3E3E3;
  border-bottom: 0;
  border-left: 0;
}

.service-box:nth-child(3) .block,
.service-box:nth-child(6) .block,
.service-box:nth-child(9) .block {
  border-right: 0;
}

@media (max-width: 991px) {
  .service-box:nth-child(2) .block,
  .service-box:nth-child(4) .block,
  .service-box:nth-child(8) .block,
  .service-box:nth-child(10) .block {
    border-right: 0;
  }
  .service-box:nth-child(3) .block {
    border-right: 1px solid #E3E3E3;
  }
}

@media (max-width: 767px) {
  .service-box .block {
    border: 0 !important;
  }
}

.service-box .service-icon {
  height: 85px;
  width: 85px;
  line-height: 90px;
  overflow: hidden;
  font-size: 35px;
}

.service-box:nth-child(1) .service-icon {
  background-color: rgba(24, 199, 209, 0.15);
  color: #1E858B;
}

.service-box:nth-child(2) .service-icon {
  background-color: rgba(255, 187, 51, 0.15);
  color: #FFBB33;
}

.service-box:nth-child(3) .service-icon {
  background-color: rgba(255, 49, 49, 0.15);
  color: #FF3158;
}

.service-box:nth-child(4) .service-icon {
  background-color: rgba(51, 145, 255, 0.15);
  color: #126dd6;
}

.service-box:nth-child(5) .service-icon {
  background-color: rgba(152, 49, 255, 0.15);
  color: #7714da;
}

.service-box:nth-child(6) .service-icon {
  background-color: rgba(27, 172, 24, 0.15);
  color: #1E858B;
}

.service-box:nth-child(7) .service-icon {
  background-color: rgba(255, 187, 51, 0.15);
  color: #FFBB33;
}

.service-box:nth-child(8) .service-icon {
  background-color: rgba(255, 49, 49, 0.15);
  color: #FF3158;
}

.service-box:nth-child(9) .service-icon {
  background-color: rgba(24, 199, 209, 0.15);
  color: #FFBB33;
}

.post-category {
  line-height: 1;
}

.post-category::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 4px;
  left: -15px;
  top: 0;
  background-color: #222222;
  transition: 0.3s;
}

.post-category:hover::before {
  background-color: #FF3158;
}

.post-blockquote {
  padding: 35px 80px;
  background-color: #F8F8F8;
  margin: 40px 0 30px 0;
  border-radius: 15px;
}

.post-blockquote h3 {
  line-height: 32px;
  position: relative;
  padding-left: 10px;
}

.post-blockquote h3:after, .post-blockquote h3:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 33px;
  background-size: contain;
  background-repeat: no-repeat;
}

.post-blockquote h3::after {
  background-image: url(../images/blogs/details/quote-right.png);
  right: -33px;
  bottom: -8px;
}

@media (max-width: 480px) {
  .post-blockquote h3::after {
    display: none;
  }
}

.post-blockquote h3::before {
  background-image: url(../images/blogs/details/quote-left.png);
  left: -45px;
  top: -7px;
}

@media (max-width: 480px) {
  .post-blockquote h3::before {
    left: 0;
    top: -30px;
  }
}

@media (max-width: 575px) {
  .post-blockquote h3 {
    padding-left: 0;
    padding-top: 20px;
  }
}

@media (max-width: 480px) {
  .post-blockquote {
    padding: 25px;
    padding-top: 50px;
  }
}

.contact-section .shape-1 {
  top: 30%;
  left: 6%;
  height: 45px;
  width: 45px;
}

.contact-section .shape-2 {
  top: 60%;
  left: 4%;
  height: 20px;
  width: 20px;
}

.contact-section .shape-3 {
  top: 30%;
  right: 6%;
  height: 45px;
  width: 45px;
}

.contact-section .shape-4 {
  bottom: 40%;
  right: 2%;
  height: 20px;
  width: 20px;
}

footer {
  padding-top: 110px;
}

footer .shape-1 {
  top: 120px;
  left: 100px;
}

footer .shape-2 {
  top: 35%;
  left: 20%;
}

@media (max-width: 767px) {
  footer .shape-2 {
    display: none;
  }
}

footer .shape-3 {
  bottom: 130px;
  left: 20px;
}

footer .shape-4 {
  right: 80px;
  top: 120px;
}

footer .shape-5 {
  right: 20%;
  top: 35%;
}

footer .shape-6 {
  right: 15px;
  bottom: 100px;
}

footer .form-control {
  border-right: 0;
  border-color: #EAEAEA;
  background-color: #EAEAEA;
}

footer .form-control:focus {
  background-color: #EAEAEA;
}

footer .needs-validation .btn {
  padding-left: 25px;
  padding-right: 25px;
}

footer .needs-validation .btn:hover {
  transform: translateY(0) !important;
}

.footer-bottom .block {
  padding: 25px 0;
}

.footer-links li {
  margin-bottom: 10px;
}

.footer-links li a {
  color: #222222;
}

.footer-links li a:hover {
  color: #FF3158;
}

.footer-links li:last-child {
  margin-bottom: 0;
}

.scroll-to-top-btn {
  bottom: 20px;
  right: 33px;
  z-index: 9999;
  padding: 3px;
  border-radius: 2px;
  opacity: 0;
  visibility: hidden;
  transition: .3s ease-out;
}

@media (max-width: 575px) {
  .scroll-to-top-btn {
    right: 15px;
  }
  .scroll-to-top-btn svg {
    height: 25px;
    width: 25px;
  }
}

.scroll-to-top-btn.show {
  opacity: 1;
  visibility: visible;
  bottom: 33px;
}

@media (max-width: 575px) {
  .scroll-to-top-btn.show {
    bottom: 15px;
  }
}
