// footer style
footer {
    padding-top: 110px;
    .shape-1 {
        top: 120px;
        left: 100px;
    }
    .shape-2 {
        top: 35%;
        left: 20%;
        @include breakAt(md-device) {
            display: none;
        }
    }
    .shape-3 {
        bottom: 130px;
        left: 20px;
    }
    .shape-4 {
        right: 80px;
        top: 120px;
    }
    .shape-5 {
        right: 20%;
        top: 35%;
    }
    .shape-6 {
        right: 15px;
        bottom: 100px;
    }
    .form-control {
        border-right: 0;
        border-color: #EAEAEA;
        background-color: #EAEAEA;
        &:focus {
            background-color: #EAEAEA;
        }
    }
    .needs-validation .btn {
        padding-left: 25px;
        padding-right: 25px;
		&:hover {
			transform: translateY(0) !important;
		}
    }
}
.footer-bottom .block {
    padding: 25px 0;
}

.footer-links {
    li {
        margin-bottom: 10px;
        a {
            color: $black-200;
            &:hover {
                color: $color-primary;
            }
        }
    }
    li:last-child {
        margin-bottom: 0;
    }
}

.scroll-to-top-btn {
    bottom: 20px;
    right: 33px;
    z-index: 9999;
    padding: 3px;
    border-radius: 2px;
    opacity: 0;
    visibility: hidden;
    transition: .3s ease-out;
    @include breakAt(sm-device) {
        right: 15px;
        svg  {
            height: 25px;
            width: 25px;
        }
    }
}
.scroll-to-top-btn.show {
    opacity: 1;
    visibility: visible;
    bottom: 33px;
    @include breakAt(sm-device) {
        bottom: 15px;
    }
}