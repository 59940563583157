/*!------------------------------------------------------------------
[MAIN STYLESHEET]

PROJECT:	Copper
VERSION:	v1.0
-------------------------------------------------------------------*/

/*------------------------------------------------------------------
[TABLE OF CONTENTS]
-------------------------------------------------------------------*/

@import 'variables';
@import 'mixins';
@import 'typography';
@import 'common';
@import 'buttons';
@import 'templates/header';
@import 'templates/main';
@import 'templates/footer';